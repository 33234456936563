<template>
  <section id="about" class="route">
    <h1 class="title">
      Create
      <hr class="divider divider--vertical" />
      <span class="accent--text">Account</span>
    </h1>

    <div class="content content--centered">
      <p>
        Welcome to
        <b>{{ appOrgName }}</b
        >! Create an account to view and register for events.
      </p>
    </div>

    <user-form
      :form-data="user"
      @updated="onUserRegistered"
      disable-role
      register-user
    />

    <div class="content content--centered">
      Already have an account?
      <hr class="divider divider--vertical" />
      <router-link :to="{ name: 'Login' }">Log in</router-link>
    </div>

    <hr class="divider divider--md" />
  </section>
</template>

<script>
import PermissionsMixin from "../components/mixins/permissions.mixin";
import UserForm from "../components/UserForm.vue";
import session from "../session";

export default {
  components: { UserForm },

  name: "RegisterUser",

  mixins: [PermissionsMixin],

  data: () => ({ user: { role: "Student" } }),

  created() {
    // Redirect to home if logged in (via permissions mixin prop)
    if (this.activeUser) this.$router.push({ name: "Home" });
  },

  methods: {
    async onUserRegistered(user) {
      const { username, password } = user;

      try {
        await session.login(username, password);
        // Navigate to next or home
        const { next } = this.$route.query;
        this.$router.push(next || { name: "Home" });
      } catch (error) {
        // this.onFormError(error);
      }
    }
  }
};
</script>
